import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import SEO from '../.././components/seo'
import SubPageLayout from '../../layouts/subPageLayout'
import PageSection from '../../common/section'
import styled from 'styled-components'
const Text = styled.div`
  flex: 1;
  //margin-top: -5rem;
`
//martin needs to re-run the prosject
const SparePartsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      deler: file(relativePath: { eq: "slitedeler.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const title = 'Verkstedkjeden BilXtra har 5 års garanti på de fleste reservedeler'
  const description1 =
    'Som et element til kundenes trygghet i valg av verksted, innfører BilXtra 5 års garanti/100 000 km på de fleste reservedeler som benyttes ved våre verksteder.'
  //Re-runn
  return (
    <SubPageLayout title={title} description1={description1}>
      <SEO title={title} />
      <Text>
        <PageSection image={data.deler.childImageSharp.fluid}>
          <p>
            Garantien er gjeldene fra 01.01.2011 og forutsetter at bilen følger bilfabrikantens
            service - og vedlikeholdsprogram hos BilXtra eller andre offentlig godkjente verksteder.
          </p>
          <p>
            Med 270 bilverksteder i BilXtra kjeden og med mål om fortsatt vekst er konseptutvikling
            og innhold særdeles viktig for oss. For å understøtte sluttbrukers trygghet i valg av
            verksted ønsker vi å synliggjøre at vi også benytter reservedeler av høy kvalitet fra
            kjente merkenavn.
          </p>
          <p>
            5 års garanti gis på de fleste reservedeler som er levert fra AS Sørensen og Balchen
            og/eller BilXtra butikk og som inngår som en del av en reparasjon eller servicejobb hos
            et BilXtra verksted. Deler som er innkjøpt fra andre leverandører f.eks en bilforhandler
            gis det maks ett års garanti på delene.
          </p>
          <p>
            Vi håper denne konseptutviklingen bidrar til enda fler fornøyde kunder hos våre
            verksteder, og at vi gjennom dette også bidrar til å forsterke kundenes trygghet i valg
            av verksted.
          </p>
        </PageSection>
      </Text>
    </SubPageLayout>
  )
}

export default SparePartsPage
